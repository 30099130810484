.logs-page {
  padding: 20px;
}
.logs-page .nav {
  line-height: 24px;
  font-size: 18px;
  color: #303133;
}
.logs-page .search-bar {
  margin-top: 10px;
}
.logs-page .content {
  margin-top: 40px;
}
.logs-page .key {
  font-weight: bold;
}
