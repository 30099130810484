.flex_h {
  display: flex;
  flex-direction: row;
}
.flex_v {
  display: flex;
  flex-direction: column;
}
.flex_wrap {
  flex-wrap: wrap;
}
.align_center {
  align-items: center;
}
.align_start {
  align-items: flex-start;
}
.align_end {
  align-items: flex-end;
}
.justify_center {
  justify-content: center;
}
.justify_around {
  justify-content: space-around;
}
.justify_between {
  justify-content: space-between;
}
.justify_start {
  justify-content: flex-start;
}
.justify_end {
  justify-content: flex-end;
}
.align_self_center {
  align-self: center;
}
.align_self_start {
  align-self: flex-start;
}
.align_self_end {
  align-self: flex-end;
}
.fz13 {
  font-size: .65rem;
}
.fz17 {
  font-size: .85rem;
}
.black {
  color: #000;
}
.pink {
  color: #ff3333;
}
.gray8 {
  color: #888;
}
