.basic-layout {
  /* 应用布局&布局设置 */
}
.basic-layout.fixed-header > .ant-layout-header {
  z-index: 3;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
}
.basic-layout > .ant-layout.ant-layout-has-sider {
  min-height: calc(100vh - 60px);
}
.basic-layout.full-layout.fixed.ant-layout,
.basic-layout.full-layout.fixed .full-layout.ant-layout {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.basic-layout.full-layout.fixed.ant-layout .ant-layout-sider > .ant-layout-sider-children,
.basic-layout.full-layout.fixed .full-layout.ant-layout .ant-layout-sider > .ant-layout-sider-children {
  overflow-y: auto;
  overflow-x: hidden;
}
.basic-layout.full-layout:not(.fixed).basic-layout.full-layout.fixed-header > .ant-layout-header {
  position: fixed;
  z-index: 200;
  left: 0;
  right: 0;
}
.basic-layout.full-layout:not(.fixed).basic-layout.full-layout.fixed-header > .ant-layout {
  padding-top: 60px;
  overflow: auto;
}
.basic-layout.full-layout:not(.fixed).basic-layout.full-layout.fixed-header .ant-layout.ant-layout-has-sider > .ant-layout,
.basic-layout.full-layout:not(.fixed).basic-layout.full-layout.fixed-header .ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: visible;
}
.basic-layout.full-layout.fixed-breadcrumbs > .ant-layout-header {
  box-shadow: none;
  z-index: 2;
}
.basic-layout.full-layout.fixed-breadcrumbs > .ant-layout > .ant-layout-content > .ant-layout > .ant-layout-content {
  overflow: auto;
}
.basic-layout.full-layout.fixed-breadcrumbs > .ant-layout > .ant-layout-content > .ant-layout > .ant-layout-header {
  z-index: 2;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
}
.basic-layout.full-layout.hided-breadcrumbs .topbar-content {
  display: none;
}
.basic-layout.full-layout.hided-breadcrumbs .router-page,
.basic-layout.full-layout.hided-breadcrumbs .router-page > .ant-layout {
  min-height: calc(100vh - 62px);
}
.basic-layout.full-layout .router-page,
.basic-layout.full-layout .router-page > .ant-layout {
  min-height: calc(100vh - 110px);
}
.basic-layout .router-page,
.basic-layout .ant-layout {
  background: #ffffff;
}
