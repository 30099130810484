.login-h5-page {
  width: 100%;
  height: 100%;
  position: relative;
}
.login-h5-page .header-img {
  width: 100%;
}
.login-h5-page .login-w {
  width: 100%;
  margin-top: 20px;
  padding: 30px;
}
.login-h5-page .tabs .tab {
  width: 50%;
  font-size: 18px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}
.login-h5-page .tabs .active-form span {
  color: #0283EF;
  border-bottom: 2px solid #0283EF;
}
.login-h5-page .login-form {
  margin: 30px 0;
}
.login-h5-page .login-form .ant-input {
  height: 40px;
}
.login-h5-page .login-form-button {
  width: 100%;
  height: 42px;
}
.login-h5-page .tip {
  text-align: center;
}
.login-h5-page .rights {
  font-size: 12px;
  color: #666666;
  line-height: 16px;
  text-align: center;
  margin-bottom: 10px;
}
